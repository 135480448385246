import React from "react";
import "./contactInfo.css";

const ContactInfo = () => {
  return (
    <div>
      <div className="contactInfo-container">
        <div className="contactInfo-center">
          <h2>
            CONTACT <span>INFORMATION</span>
          </h2>
          <p>
            Extended Trust Client Care Program is dedicated to covering your
            absolute satisfaction every moment
          </p>
          <h3>HEAD OFFICE</h3>
          <div className="headOffice-container">
            <div>
              TOLL FREE
              <span>1 888 418 1711</span>
            </div>

            <div>
              EMAIL
              <span>support@extendedtrust.ca</span>
            </div>
          </div>
        </div>
      </div>
      {/* Location Section */}
      <div className="contact-container-flex">
        <div className="left-shape-container-contact">
          <div class="shapecontact">
            <div class="inner-shape-contact">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2370.5460633031244!2d-113.52762118661902!3d53.548018972233116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53a023cd4ad5fd71%3A0x73fd64207f42b58d!2s11729%20105%20Ave%20NW%20%23200%2C%20Edmonton%2C%20AB%20T5H%200L9!5e0!3m2!1sen!2sca!4v1733882031756!5m2!1sen!2sca"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                className="w-100 h-100 rounded"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="right-shape-container-contact">
          <div className="contact-address-head-section">
            <h5>Extended Trust</h5>
            <a href="#">Unit 200-11729 105 Ave. Edmonton, AB T5H 0L9</a>
          </div>

          <div class="panel-text-wrapper">
            <div class="">
              <div class="">
                <div className="close-div d-flex align-items-center">
                  <p class="open-text mb-s0">Our Business Hours</p>
                  {/* <h5 class="fancy-text">HOURS</h5> */}
                </div>

                <table class="hours-table">
                  <tr>
                    <th>Monday:</th>
                    <td>9:00am - 5:00pm</td>
                  </tr>
                  <tr>
                    <th>Tuesday:</th>
                    <td>9:00am - 5:00pm</td>
                  </tr>
                  <tr>
                    <th>Wednesday:</th>
                    <td>9:00am - 5:00pm</td>
                  </tr>
                  <tr class="today">
                    <th>Thursday:</th>
                    <td>9:00am - 5:00pm</td>
                  </tr>
                  <tr>
                    <th>Friday:</th>
                    <td>9:00am - 5:00pm</td>
                  </tr>
                  <tr>
                    <th>Saturday:</th>
                    <td>Closed</td>
                  </tr>
                  <tr>
                    <th>Sunday:</th>
                    <td>Closed</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Location Section */}
    </div>
  );
};

export default ContactInfo;
